document.addEventListener("DOMContentLoaded", () => {
  const hamburgerButtonSet = document.querySelector(".hamburger-button-set");
  const sidebar = document.querySelector(".sidebar");
  const overlay = document.querySelector(".overlay");
  const breakPoint = 686; // ブレークポイントを設定

  let manualToggle = false; // ユーザーが手動で開閉を操作したかを追跡するフラグ

  // 保存されたサイドバーの状態を読み込む
  const savedSidebarState = sessionStorage.getItem("sidebarState");
  updateSidebar(savedSidebarState);

  // window.addEventListener('resize', function () {
  //   // ブレークポイントに基づいてサイドバーの表示を切り替える
  //   if (window.innerWidth <= breakPoint) {
  //     closeSidebar();
  //     if (hamburgerButtonSet) hamburgerButtonSet.style.display = "block";
  //   } else {
  //     openSidebar();
  //     if (hamburgerButtonSet) hamburgerButtonSet.style.display = "none";
  //   }
  // });

  const menuItems = document.querySelectorAll(".large-category");
  menuItems.forEach((item) => {
    item.addEventListener("click", () => {
      if (window.innerWidth <= breakPoint) {
        closeSidebar();
      }
    });
  });

  if (hamburgerButtonSet) {
    hamburgerButtonSet.addEventListener("click", () => {
      toggleMenu();
      manualToggle = true; // ユーザーが手動で開閉を操作したのでフラグを更新

      // サイドバーの状態を保存
      if (sidebar.classList.contains('show')) {
        sessionStorage.setItem("sidebarState", "open");
      } else {
        sessionStorage.setItem("sidebarState", "closed");
      }
    });
  }

  function toggleMenu() {
    if (sidebar && overlay) {
      sidebar.classList.toggle("show");
      overlay.style.display = overlay.style.display === "block" ? "none" : "block";
    }
  }

  if (overlay) {
    overlay.addEventListener("click", closeSidebar);
  }

  function saveSidebarScrollPosition() {
    sessionStorage.setItem("sidebarScrollPosition", document.getElementById("sidebar-contents").scrollTop);
  }

  const sidebarContents = document.getElementById("sidebar-contents");
  if (sidebarContents) {
    sidebarContents.addEventListener("scroll", saveSidebarScrollPosition);
  }

  // Call the restoreSidebarScrollPosition function
  restoreSidebarScrollPosition();

  function closeSidebar() {
    if (sidebar && overlay) {
      sidebar.classList.remove("show");
      overlay.style.display = "none";
      manualToggle = false; // ユーザーが手動で開閉を操作したのでフラグを更新
      sessionStorage.setItem("sidebarState", "closed"); // サイドバーの状態を保存
    }
  }

  function openSidebar() {
    if (sidebar) {      sidebar.classList.add("show");
  }
}

function updateSidebar(state) {
  if (state === "open") {
    openSidebar();
  } else if (state === "closed") {
    closeSidebar();
  } else {
    // ブレークポイントに基づいてサイドバーの表示を切り替える
    if (window.innerWidth <= breakPoint) {
      closeSidebar();
      if (hamburgerButtonSet) hamburgerButtonSet.style.display = "block";
    } else {
      openSidebar();
      if (hamburgerButtonSet) hamburgerButtonSet.style.display = "none";
    }
  }
}

function restoreSidebarScrollPosition() {
  const savedScrollPosition = sessionStorage.getItem("sidebarScrollPosition");
  if (savedScrollPosition) {
    document.getElementById("sidebar-contents").scrollTop = savedScrollPosition;
  }
}
});
